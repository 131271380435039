<template>
  <div>
    <b-modal
      ref="refModalErrorMessage"
      ok-only
      ok-variant="danger"
      ok-title="ตกลง"
      modal-class="modal-danger"
      centered
      title="กรุณาแก้ไขข้อมูล"
    >
      <b-card-text>
        {{ errorMessage }}
      </b-card-text>
    </b-modal>
    <b-card
      :title="`รายการสัญญา ฟอร์ม ${resolveFormType == 'add' ? 'เพิ่มข้อมูล' : resolveFormType == 'edit' ? 'แก้ไขข้อมูล' : 'ลบข้อมูล'}`"
    >
      <b-tabs
        v-model="tabIndex"
        class="tab-inform-claim"
      >
        <!-- ฟอร์มที่ 1 -->
        <b-tab>
          <template #title>
            <span>ข้อมูลลูกค้า</span>
          </template>
          <b-card-text>
            <template v-if="tabIndex == 0">
              <contract-maker-form1
                :tab-index.sync="tabIndex"
                :resolve-form-type.sync="resolveFormType"
                :branch-code.sync="branchCode"
                :branch-name.sync="branchName"
                :input-reg-num1.sync="inputRegNum1"
                :input-reg-num2.sync="inputRegNum2"
                :input-cpn-brn-cod.sync="inputCpnBrnCod"
                :chassis-num.sync="chassisNum"
                :input-gen-app-num.sync="inputGenAppNum"
                :input-con-num.sync="inputConNum"
                :input-coo-perate-code.sync="inputCooPerateCode"
                :input-psn-reg-idn.sync="inputPsnRegIdn"
                :input-car-location.sync="inputCarLocation"
                :sales-code.sync="salesCode"
                :sales-name.sync="salesName"
                :cus-psn-code.sync="cusPsnCode"
                :cus-psn-name.sync="cusPsnName"
                :input-id-str-date.sync="inputIdStrDate"
                :input-id-end-date.sync="inputIdEndDate"
                :input-bth-date.sync="inputBthDate"
                :input-drv-lic-str.sync="inputDrvLicStr"
                :input-drv-lic-end.sync="inputDrvLicEnd"
                :input-cus-nam-tha.sync="inputCusNamTha"
                :input-cus-sur-tha.sync="inputCusSurTha"
                :input-drv-license-no.sync="inputDrvLicenseNo"
                :input-adr-dtlln1.sync="inputAdrDtlln1"
                :input-adr-dis-trict.sync="inputAdrDisTrict"
                :input-adr-am-phur.sync="inputAdrAmPhur"
                :input-adr-pro-vince.sync="inputAdrProVince"
                :input-adr-zip-cod.sync="inputAdrZipCod"
                :input-cus-phn-num.sync="inputCusPhnNum"
                :input-adr-tel-num.sync="inputAdrTelNum"
                :input-cus-email.sync="inputCusEmail"
                :input-line-id.sync="inputLineId"
              />
            </template>
          </b-card-text>
        </b-tab>

        <!-- ฟอร์มที่ 2 -->
        <b-tab title-item-class="ml-2">
          <template #title>
            <span>ข้อมูลผู้ค้ำประกัน</span>
          </template>
          <b-card-text>
            <template v-if="tabIndex == 1">
              <contract-maker-form2
                :tab-index.sync="tabIndex"
                :resolve-form-type.sync="resolveFormType"
                :input-psn-reg-idn-gur.sync="inputPsnRegIdnGur"
                :cus-psn-gur-code.sync="cusPsnGurCode"
                :cus-psn-gur-name.sync="cusPsnGurName"
                :input-id-str-date-gur.sync="inputIdStrDateGur"
                :input-id-end-date-gur.sync="inputIdEndDateGur"
                :input-bth-date-gur.sync="inputBthDateGur"
                :input-cus-nam-tha-gur.sync="inputCusNamThaGur"
                :input-cus-sur-tha-gur.sync="inputCusSurThaGur"
                :input-adr-dtlln1-gur.sync="inputAdrDtlln1Gur"
                :input-adr-dis-trict-gur.sync="inputAdrDisTrictGur"
                :input-adr-am-phur-gur.sync="inputAdrAmPhurGur"
                :input-adr-pro-vince-gur.sync="inputAdrProVinceGur"
                :input-adr-zip-cod-gur.sync="inputAdrZipCodGur"
                :input-cus-phn-num-gur.sync="inputCusPhnNumGur"
                :input-adr-tel-num-gur.sync="inputAdrTelNumGur"
                :input-cus-email-gur.sync="inputCusEmailGur"
                :input-line-id-gur.sync="inputLineIdGur"
              />
            </template>
          </b-card-text>
        </b-tab>

        <!-- ฟอร์ม 3 -->
        <b-tab title-item-class="ml-2">
          <template #title>
            <span>เงื่อนไขการเช่า</span>
          </template>
          <b-card-text>
            <template v-if="tabIndex == 2">
              <contract-maker-form3
                :tab-index.sync="tabIndex"
                :resolve-form-type.sync="resolveFormType"
                :input-cnd-re-mark1.sync="inputCndReMark1"
                :input-cnd-re-mark2.sync="inputCndReMark2"
                :input-cnd-re-mark3.sync="inputCndReMark3"
                :input-cnd-re-mark4.sync="inputCndReMark4"
                :input-cnd-re-mark5.sync="inputCndReMark5"
                :input-cnd-re-mark6.sync="inputCndReMark6"
                :input-cnd-re-mark7.sync="inputCndReMark7"
                :input-cnd-re-mark8.sync="inputCndReMark8"
                :input-cnd-re-mark9.sync="inputCndReMark9"
                :input-cnd-re-mark10.sync="inputCndReMark10"
                :input-search-follower.sync="inputSearchFollower"
                :input-reg-num1.sync="inputRegNum1"
                :input-reg-num2.sync="inputRegNum2"
                :chassis-num.sync="chassisNum"

                :input-eff-str-date.sync="inputEffStrDate"
                :input-eff-end-date.sync="inputEffEndDate"
                :input-cnd-rent-year.sync="inputCndRentYear"
                :input-cnd-rent-month.sync="inputCndRentMonth"
                :input-cnd-rent-amt1.sync="inputCndRentAmt1"
                :input-cnd-rent-amt2.sync="inputCndRentAmt2"
                :input-cnd-rent-amt3.sync="inputCndRentAmt3"
                :input-cnd-rent-amt4.sync="inputCndRentAmt4"
                :input-cnd-rent-amt5.sync="inputCndRentAmt5"
                :input-cnd-rent-amt6.sync="inputCndRentAmt6"
                :input-cnd-rent-amt7.sync="inputCndRentAmt7"
                :input-cnd-rent-amt8.sync="inputCndRentAmt8"
                :input-cnd-rent-amt9.sync="inputCndRentAmt9"
                :input-cnd-rent-amt10.sync="inputCndRentAmt10"
                :input-comm-amount.sync="inputCommAmount"
                :input-temp-rent.sync="inputTempRent"
                :input-file-name.sync="inputFileName"
                :url-file-name.sync="urlFileName"
                :file-name.sync="fileName"
                :error-message.sync="errorMessage"
                @fnc-click-submit="fncClickSubmit"
              />
            </template>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab, BCardText,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import ContractMakerForm1 from './ContractMakerForm1.vue'
import ContractMakerForm2 from './ContractMakerForm2.vue'
import ContractMakerForm3 from './ContractMakerForm3.vue'

import ContractMakerStoreModule from './ContractMakerStoreModule'
// eslint-disable-next-line import/order
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/order
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BCardText,

    ContractMakerForm1,
    ContractMakerForm2,
    ContractMakerForm3,
  },

  // props: {
  //   dataResult : {
  //     type: [Array, Object],
  //     required: true,
  //   },
  // },

  setup() {
    const APP_STORE_MODULE_NAME = 'appContractMaker'
    const toast = useToast()

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, ContractMakerStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    // })

    // ========== ส่วนของ Form 1 ==========
    // ประกาศตัวแปร Taxtbox
    const inputRegNum1 = ref(null)
    const inputRegNum2 = ref(null)
    const inputCpnBrnCod = ref(null)
    const inputGenAppNum = ref(null)
    const inputConNum = ref(null)
    const inputCooPerateCode = ref(null)
    const inputCarLocation = ref(null)
    const inputPsnRegIdn = ref(null)
    const inputIdStrDate = ref(null)
    const inputIdEndDate = ref(null)
    const inputBthDate = ref(null)
    const inputDrvLicStr = ref(null)
    const inputDrvLicEnd = ref(null)
    const inputCusNamTha = ref(null)
    const inputCusSurTha = ref(null)
    const inputDrvLicenseNo = ref(null)
    const inputAdrDtlln1 = ref(null)
    const inputAdrDisTrict = ref(null)
    const inputAdrAmPhur = ref(null)
    const inputAdrProVince = ref(null)
    const inputAdrZipCod = ref(null)
    const inputCusPhnNum = ref(null)
    const inputAdrTelNum = ref(null)
    const inputCusEmail = ref(null)
    const inputLineId = ref(null)
    const getChassisNumber = router.currentRoute.params.chassis_number

    // ประกาศตัวแปรสำหรับเก็บค่า
    const tabIndex = ref(0) // ตัวแปร Active form 1,2,3
    const branchCode = ref(null)
    const branchName = ref(null)
    const chassisNum = ref(null)
    const salesCode = ref(null)
    const salesName = ref(null)
    const cusPsnCode = ref(null)
    const cusPsnName = ref(null)
    // ========== ตัวแปร Form 1 ==========
    // ========== ส่วนของ Form 1 ==========

    // ========== ส่วนของ Form 2 ==========
    // ประกาศตัวแปร Taxtbox
    const inputPsnRegIdnGur = ref(null)
    const inputIdStrDateGur = ref(null)
    const inputIdEndDateGur = ref(null)
    const inputBthDateGur = ref(null)
    const inputCusNamThaGur = ref(null)
    const inputCusSurThaGur = ref(null)
    const inputAdrDtlln1Gur = ref(null)
    const inputAdrDisTrictGur = ref(null)
    const inputAdrAmPhurGur = ref(null)
    const inputAdrProVinceGur = ref(null)
    const inputAdrZipCodGur = ref(null)
    const inputCusPhnNumGur = ref(null)
    const inputAdrTelNumGur = ref(null)
    const inputCusEmailGur = ref(null)
    const inputLineIdGur = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const cusPsnGurName = ref(null)
    const cusPsnGurCode = ref(null)
    // ========== ตัวแปร Form 2 ==========
    // ========== ส่วนของ Form 2 ==========

    // ========== ส่วนของ Form 3 ==========
    // ประกาศตัวแปร Taxtbox
    const inputEffStrDate = ref(null)
    const inputEffEndDate = ref(null)
    const inputCndRentYear = ref(null)
    const inputCndRentMonth = ref(null)
    const inputCndRentAmt1 = ref(null)
    const inputCndRentAmt2 = ref(null)
    const inputCndRentAmt3 = ref(null)
    const inputCndRentAmt4 = ref(null)
    const inputCndRentAmt5 = ref(null)
    const inputCndRentAmt6 = ref(null)
    const inputCndRentAmt7 = ref(null)
    const inputCndRentAmt8 = ref(null)
    const inputCndRentAmt9 = ref(null)
    const inputCndRentAmt10 = ref(null)
    const inputCommAmount = ref(null)
    const inputTempRent = ref(null)
    const inputCndReMark1 = ref(null)
    const inputCndReMark2 = ref(null)
    const inputCndReMark3 = ref(null)
    const inputCndReMark4 = ref(null)
    const inputCndReMark5 = ref(null)
    const inputCndReMark6 = ref(null)
    const inputCndReMark7 = ref(null)
    const inputCndReMark8 = ref(null)
    const inputCndReMark9 = ref(null)
    const inputCndReMark10 = ref(null)
    const inputSearchFollower = ref(null)

    const errorMessage = ref(null)
    // ประกาศตัวแปรสำหรับเก็บค่า

    // ประกาศตัวแปร FileUpload
    const inputFileName = ref(null)
    const urlFileName = ref(null)
    const fileName = ref({})

    const refModalErrorMessage = ref(null)
    // ========== ตัวแปร Form 3 ==========
    // ========== ส่วนของ Form 3 ==========

    const resolveFormType = computed(() => {
      let setFormType = ''
      if (router.currentRoute.name === 'contract-maker-form-add') {
        setFormType = 'add'
      } else if (
        router.currentRoute.name === 'contract-maker-form-edit'
      ) {
        setFormType = 'edit'
      } else {
        setFormType = 'remove'
      }

      return setFormType
    })

    const getConnum = router.currentRoute.params.connum
    if (getConnum) {
      const payload = {
        action: 'EDIT',
        events: 'MAKER',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        connum_in: getConnum,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CONTRACT_MASTER`, payload)
        .then(response => {
          console.log(response)
          const getFullRegNum = response.data.responseData[0].regnum
          const splitRegNum = getFullRegNum.split('-')
          // eslint-disable-next-line prefer-destructuring
          inputRegNum1.value = splitRegNum[0]
          // eslint-disable-next-line prefer-destructuring
          inputRegNum2.value = splitRegNum[1]
          branchCode.value = response.data.responseData[0].cpnbrncod
          inputCpnBrnCod.value = response.data.responseData[0].cpnbrncod_desc
          chassisNum.value = response.data.responseData[0].chassis_number
          inputGenAppNum.value = response.data.responseData[0].genappnum
          inputCooPerateCode.value = response.data.responseData[0].cooperate_code
          inputPsnRegIdn.value = response.data.responseData[0].psnregidn
          inputCarLocation.value = response.data.responseData[0].car_location
          salesCode.value = response.data.responseData[0].acccodmkt
          salesName.value = response.data.responseData[0].sales_name
          cusPsnCode.value = response.data.responseData[0].custtltha
          cusPsnName.value = response.data.responseData[0].custtltha
          inputConNum.value = response.data.responseData[0].connum
          inputCarLocation.value = response.data.responseData[0].car_location
          inputIdStrDate.value = moment(response.data.responseData[0].psnregsin).format('YYYY-MM-DD')
          inputIdEndDate.value = moment(response.data.responseData[0].psnregexp).format('YYYY-MM-DD')
          inputCusNamTha.value = response.data.responseData[0].cusnamtha
          inputCusSurTha.value = response.data.responseData[0].cussurtha
          inputBthDate.value = moment(response.data.responseData[0].psnbthdte).format('YYYY-MM-DD')
          inputDrvLicenseNo.value = response.data.responseData[0].drv_license_no
          inputDrvLicStr.value = moment(response.data.responseData[0].drv_license_str).format('YYYY-MM-DD')
          inputDrvLicEnd.value = moment(response.data.responseData[0].drv_license_exp).format('YYYY-MM-DD')
          inputAdrDtlln1.value = response.data.responseData[0].adrdtlln1
          inputAdrDisTrict.value = response.data.responseData[0].adrdistrict
          inputAdrAmPhur.value = response.data.responseData[0].adramphur
          inputAdrProVince.value = response.data.responseData[0].adrprovince
          inputAdrZipCod.value = response.data.responseData[0].adrzipcod
          inputCusPhnNum.value = response.data.responseData[0].cusphnnum
          inputAdrTelNum.value = response.data.responseData[0].adrtelnum
          inputCusEmail.value = response.data.responseData[0].cusemail
          inputLineId.value = response.data.responseData[0].line_id
          cusPsnGurCode.value = response.data.responseData[0].custtltha_gur
          cusPsnGurName.value = response.data.responseData[0].custtltha_gur
          inputPsnRegIdnGur.value = response.data.responseData[0].psnregidn_gur
          inputIdStrDateGur.value = moment(response.data.responseData[0].psnregsin_gur).format('YYYY-MM-DD')
          inputIdEndDateGur.value = moment(response.data.responseData[0].psnregexp_gur).format('YYYY-MM-DD')
          inputBthDateGur.value = moment(response.data.responseData[0].psnbthdte_gur).format('YYYY-MM-DD')
          inputCusNamThaGur.value = response.data.responseData[0].cusnamtha_gur
          inputCusSurThaGur.value = response.data.responseData[0].cussurtha_gur
          inputAdrDtlln1Gur.value = response.data.responseData[0].adrdtlln1_gur
          inputAdrDisTrictGur.value = response.data.responseData[0].adrdistrict_gur
          inputAdrAmPhurGur.value = response.data.responseData[0].adramphur_gur
          inputAdrProVinceGur.value = response.data.responseData[0].adrprovince_gur
          inputAdrZipCodGur.value = response.data.responseData[0].adrzipcod_gur
          inputCusPhnNumGur.value = response.data.responseData[0].cusphnnum_gur
          inputAdrTelNumGur.value = response.data.responseData[0].adrtelnum_gur
          inputCusEmailGur.value = response.data.responseData[0].cusemail_gur
          inputLineIdGur.value = response.data.responseData[0].line_id_gur

          inputEffStrDate.value = response.data.responseData[0].cnd_rent_start
          inputEffEndDate.value = response.data.responseData[0].cnd_rent_end
          inputCndRentYear.value = response.data.responseData[0].cnd_rent_year
          inputCndRentMonth.value = response.data.responseData[0].cnd_rent_month
          inputCndRentAmt1.value = response.data.responseData[0].cnd_rent_amt1
          inputCndRentAmt2.value = response.data.responseData[0].cnd_rent_amt2
          inputCndRentAmt3.value = response.data.responseData[0].cnd_rent_amt3
          inputCndRentAmt4.value = response.data.responseData[0].cnd_rent_amt4
          inputCndRentAmt5.value = response.data.responseData[0].cnd_rent_amt5
          inputCndRentAmt6.value = response.data.responseData[0].cnd_rent_amt6
          inputCndRentAmt7.value = response.data.responseData[0].cnd_rent_amt7
          inputCndRentAmt8.value = response.data.responseData[0].cnd_rent_amt8
          inputCndRentAmt9.value = response.data.responseData[0].cnd_rent_amt9
          inputCndRentAmt10.value = response.data.responseData[0].cnd_rent_amt10
          inputCommAmount.value = response.data.responseData[0].commission_amount
          inputTempRent.value = response.data.responseData[0].temp_rent

          inputCndReMark1.value = response.data.responseData[0].cnd_remark1
          inputCndReMark2.value = response.data.responseData[0].cnd_remark2
          inputCndReMark3.value = response.data.responseData[0].cnd_remark3
          inputCndReMark4.value = response.data.responseData[0].cnd_remark4
          inputCndReMark5.value = response.data.responseData[0].cnd_remark5
          inputCndReMark6.value = response.data.responseData[0].cnd_remark6
          inputCndReMark7.value = response.data.responseData[0].cnd_remark7
          inputCndReMark8.value = response.data.responseData[0].cnd_remark8
          inputCndReMark9.value = response.data.responseData[0].cnd_remark9
          inputCndReMark10.value = response.data.responseData[0].cnd_remark10
          inputSearchFollower.value = response.data.responseData[0].acccodfol
        })
    }

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySaveButton = ref(false)
    const overlaySubmitButton = ref(false)

    /* ฟังก์ชั่นเมื่อกดปุ่ม Submit */
    const fncClickSubmit = async isSave => {
      overlaySubmitButton.value = true
      setInterval(() => { overlaySubmitButton.value = false }, 5000)

      const payload = {

        action: 'ADD',
        events: 'MAKER',
        function: 'SUBMIT',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        CPNCOD_IN: '0001',
        CONNUM_IN: inputConNum.value,
        REGNUM1_IN: inputRegNum1.value,
        REGNUM2_IN: inputRegNum2.value,
        CHASSISNUM_IN: chassisNum.value,
        CUSTOMER_IN: null,
        GENAPPNUM_IN: null,
        ACCCODMKT_IN: salesCode.value,

        PSNREGIDN_IN: inputPsnRegIdn.value,
        PSNREGSIN_IN: inputIdStrDate.value,
        PSNREGEXP_IN: inputIdEndDate.value,
        CUSTTLTHA_IN: cusPsnCode.value,
        CUSNAMTHA_IN: inputCusNamTha.value,
        CUSSURTHA_IN: inputCusSurTha.value,
        PSNBTHDTE_IN: inputBthDate.value,
        DRV_LICENSE_NO_IN: inputDrvLicenseNo.value,
        DRV_LICENSE_STR_IN: inputDrvLicStr.value,
        DRV_LICENSE_EXP_IN: inputDrvLicEnd.value,

        ADRDTLLN1_IN: inputAdrDtlln1.value,
        ADRDISTRICT_IN: inputAdrDisTrict.value,
        ADRAMPHUR_IN: inputAdrAmPhur.value,
        ADRPROVINCE_IN: inputAdrProVince.value,
        ADRZIPCOD_IN: inputAdrZipCod.value,
        CUSPHNNUM_IN: inputCusPhnNum.value,
        ADRTELNUM_IN: inputAdrTelNum.value,
        CUSEMAIL_IN: inputCusEmail.value,
        LINE_ID_IN: inputLineId.value,

        PSNREGIDN_GUR_IN: inputPsnRegIdnGur.value,
        PSNREGSIN_GUR_IN: inputIdStrDateGur.value,
        PSNREGEXP_GUR_IN: inputIdEndDateGur.value,
        CUSTTLTHA_GUR_IN: cusPsnGurCode.value,
        CUSNAMTHA_GUR_IN: inputCusNamThaGur.value,
        CUSSURTHA_GUR_IN: inputCusSurThaGur.value,
        PSNBTHDTE_GUR_IN: inputBthDateGur.value,

        ADRDTLLN1_GUR_IN: inputAdrDtlln1Gur.value,
        ADRDISTRICT_GUR_IN: inputAdrDisTrictGur.value,
        ADRAMPHUR_GUR_IN: inputAdrAmPhurGur.value,
        ADRPROVINCE_GUR_IN: inputAdrProVinceGur.value,
        ADRZIPCOD_GUR_IN: inputAdrZipCodGur.value,
        CUSPHNNUM_GUR_IN: inputCusPhnNumGur.value,
        ADRTELNUM_GUR_IN: inputAdrTelNumGur.value,
        CUSEMAIL_GUR_IN: inputCusEmailGur.value,
        LINE_ID_GUR_IN: inputLineIdGur.value,

        CND_RENT_YEAR_IN: inputCndRentYear.value,
        CND_RENT_MONTH_IN: inputCndRentMonth.value,
        CND_RENT_START_IN: inputEffStrDate.value,
        CND_RENT_END_IN: inputEffEndDate.value,
        CND_RENT_TERM1_IN: null,
        CND_RENT_AMT1_IN: inputCndRentAmt1.value,
        CND_RENT_TERM2_IN: null,
        CND_RENT_AMT2_IN: inputCndRentAmt2.value,
        CND_RENT_TERM3_IN: null,
        CND_RENT_AMT3_IN: inputCndRentAmt3.value,
        CND_RENT_TERM4_IN: null,
        CND_RENT_AMT4_IN: inputCndRentAmt4.value,
        CND_RENT_TERM5_IN: null,
        CND_RENT_AMT5_IN: inputCndRentAmt5.value,
        CND_RENT_TERM6_IN: null,
        CND_RENT_AMT6_IN: inputCndRentAmt6.value,
        CND_RENT_TERM7_IN: null,
        CND_RENT_AMT7_IN: inputCndRentAmt7.value,
        CND_RENT_TERM8_IN: null,
        CND_RENT_AMT8_IN: inputCndRentAmt8.value,
        CND_RENT_TERM9_IN: null,
        CND_RENT_AMT9_IN: inputCndRentAmt9.value,
        CND_RENT_TERM10_IN: null,
        CND_RENT_AMT10_IN: inputCndRentAmt10.value,
        COMMISSION_AMOUNT_IN: inputCommAmount.value,
        TEMP_RENT_IN: inputTempRent.value,
        CND_REMARK1_IN: inputCndReMark1.value,
        CND_REMARK2_IN: inputCndReMark2.value,
        CND_REMARK3_IN: inputCndReMark3.value,
        CND_REMARK4_IN: inputCndReMark4.value,
        CND_REMARK5_IN: inputCndReMark5.value,
        CND_REMARK6_IN: inputCndReMark6.value,
        CND_REMARK7_IN: inputCndReMark7.value,
        CND_REMARK8_IN: inputCndReMark8.value,
        CND_REMARK9_IN: inputCndReMark9.value,
        CND_REMARK10_IN: inputCndReMark10.value,

        DOCUMENT_FOLDER_IN: urlFileName.value,
        // DOCUMENT_FOLDER_IN: 'JVBERi0xLjQKJeLjz9MKMyAwIG9iago8PC9UeXBlIC9QYWdlCi9QYXJlbnQgMSAwIFIKL01lZGlhQm94IFswIDAgNTk1LjI4MCA4NDEuODkwXQovVHJpbUJveCBbMC4wMDAgMC4wMDAgNTk1LjI4MCA4NDEuODkwXQovUmVzb3VyY2VzIDIgMCBSCi9Hcm91cCA8PCAvVHlwZSAvR3JvdXAgL1MgL1RyYW5zcGFyZW5jeSAvQ1MgL0RldmljZVJHQiA',
        ACCCODFOL_IN: inputSearchFollower.value,
      }
      console.log('urlfilename', urlFileName.value)
      // console.log(payload)
      // if (getUrlFileName.value) console.log('อัพไฟล์', getUrlFileName.value)

      // eslint-disable-next-line camelcase
      if (isSave) payload.function = 'SAVE'

      if (resolveFormType.value === 'add') {
        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CONTRACT_MASTER`, payload)
          .then(response => {
            if (response.data.responseData[0].success === 'ERROR') {
              errorMessage.value = response.data.responseData[0].error_messagex
              console.log('erroradd')
            } else {
              errorMessage.value = null
              toast({
                component: ToastificationContent,
                props: {
                  title: 'แจ้งเตือน',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'บันทึกข้อมูลสำเร็จ',
                },
              })
            }
            console.log('SP_RT_UPD_CONTRACT_MASTER', response)
            router.push('/contract_maker') // คำสั่ง Redirect
            console.log('add', response)
          })
      } else if (resolveFormType.value === 'edit') {
        /* กรณีแก้ไขข้อมูลคำสั่งนี้จะทำงาน */
        payload.action = 'EDIT'
        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CONTRACT_MASTER`, payload)
          .then(response => {
            if (response.data.responseData[0].success === 'ERROR') {
              errorMessage.value = response.data.responseData[0].error_messagex
              console.log('erroredit', response.data.responseData[0].error_messagex)
              refModalErrorMessage.value.show()
            } else {
              errorMessage.value = null
              router.push('/contract_maker') // คำสั่ง Redirect
              toast({
                component: ToastificationContent,
                props: {
                  title: 'แจ้งเตือน',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'แก้ไขข้อมูลสำเร็จ',
                },
              })
            }
            console.log('edit', response)
          })
      } else {
        payload.action = 'CANCEL'

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CONTRACT_MASTER`, payload)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'แจ้งเตือน',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'ลบข้อมูลสำเร็จ',
              },
            })
            router.push('/contract_maker') // คำสั่ง Redirect
          })
      }
      console.log('payload', payload)
    }

    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }
    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    return {
      fncClickSubmit,
      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySaveButton,
      overlaySubmitButton,

      // ========== ส่วนของ Form 1 ==========
      // ประกาศตัวแปร Taxtbox
      inputRegNum1,
      inputRegNum2,
      inputCpnBrnCod,
      inputGenAppNum,
      inputConNum,
      inputCooPerateCode,
      inputCarLocation,
      inputPsnRegIdn,
      inputIdStrDate,
      inputIdEndDate,
      inputBthDate,
      inputDrvLicStr,
      inputDrvLicEnd,
      inputCusNamTha,
      inputCusSurTha,
      inputDrvLicenseNo,
      inputAdrDtlln1,
      inputAdrDisTrict,
      inputAdrAmPhur,
      inputAdrProVince,
      inputAdrZipCod,
      inputCusPhnNum,
      inputAdrTelNum,
      inputCusEmail,
      inputLineId,

      // ประกาศตัวแปรสำหรับเก็บค่า
      tabIndex,
      resolveFormType,
      branchCode,
      branchName,
      chassisNum,
      salesCode,
      salesName,
      cusPsnCode,
      cusPsnName,
      // ========== ส่วนของ Form 1 ==========

      // ========== ส่วนของ Form 2 ==========
      // ประกาศตัวแปร Taxtbox
      inputPsnRegIdnGur,
      inputIdStrDateGur,
      inputIdEndDateGur,
      inputBthDateGur,
      inputEffStrDate,
      inputEffEndDate,
      inputCusNamThaGur,
      inputCusSurThaGur,
      inputAdrDtlln1Gur,
      inputAdrDisTrictGur,
      inputAdrAmPhurGur,
      inputAdrProVinceGur,
      inputAdrZipCodGur,
      inputCusPhnNumGur,
      inputAdrTelNumGur,
      inputCusEmailGur,
      inputLineIdGur,
      inputCndRentYear,
      inputCndRentMonth,
      inputCndRentAmt1,
      inputCndRentAmt2,
      inputCndRentAmt3,
      inputCndRentAmt4,
      inputCndRentAmt5,
      inputCndRentAmt6,
      inputCndRentAmt7,
      inputCndRentAmt8,
      inputCndRentAmt9,
      inputCndRentAmt10,
      inputCommAmount,
      inputTempRent,

      errorMessage,

      // ประกาศตัวแปรสำหรับเก็บค่า
      cusPsnGurName,
      cusPsnGurCode,

      refModalErrorMessage,
      // ========== ส่วนของ Form 2 ==========

      // ========== ส่วนของ Form 3 ==========
      // ประกาศตัวแปร Taxtbox
      inputCndReMark1,
      inputCndReMark2,
      inputCndReMark3,
      inputCndReMark4,
      inputCndReMark5,
      inputCndReMark6,
      inputCndReMark7,
      inputCndReMark8,
      inputCndReMark9,
      inputCndReMark10,
      inputSearchFollower,

      // ประกาศตัวแปร FileUpload
      inputFileName,
      urlFileName,
      fileName,

      // ประกาศตัวแปรสำหรับเก็บค่า

      // ========== ส่วนของ Form 3 ==========
      getChassisNumber,
      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,

    }
  },
}
</script>

<style>

</style>
