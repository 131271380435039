import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    SP_CONFIG(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Master/SearchConfig', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_GET_CAR_MASTER_LIST(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/CarMasterMaintainance/GetCarMasterList', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_GET_ACCOUNT_MASTER(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Master/SearchGetAccountMaster', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_GET_FOLLOWER(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/DailyReceiveReport/GetFollwer', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_UPD_RENT_CONDITION(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ContractMaker/RentCondition', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_UPD_CONTRACT_MASTER(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ContractMaker/UpdateContractMaster', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_RT_GET_CAR_REG(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.post('/RepaymentInquiry/GetCarReg', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    SP_GET_CUSTOMER_BY_IDV2(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ContractMaker/Getcusbyid', { requestData: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
